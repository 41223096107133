<template lang="pug">
TheDetail(
	:groups="groups"
	title="ПТС"
	title-new="Создать ПТС"
	@remove="removeFactory"
	@save="saveFactory"
)
ModalWorkers(v-model:show="modal" endpoint="receivingTransportShip" @create="createWorker" :filter="{filterValue: 'PTS', filterKey: 'authorities', path: 'name'}")
</template>

<script>
import { computed, onMounted, reactive, ref } from 'vue'

import { useApi } from '../modules/api'
import { useBasic } from '../modules/composition/inputs/useBasic'
import { useDetail } from '../modules/composition/useDetail'
import { useWorkers } from '../modules/composition/useWorkers'
import { useFishes } from '../modules/composition/useFishes'

import TheDetail from '../components/TheDetail'
import ModalWorkers from '../components/modals/ModalWorkers'

export default {
	name: 'PortDetail',
	components: { TheDetail, ModalWorkers },
	setup () {
		const { basic, setBasic, bodyBasic } = useBasic()

		const { idDetail, isNew, notFoundDetail, saveDetail, removeDetail } = useDetail()

		const saveFactory = async () => {
			const body = bodyBasic()

			await saveDetail({
				endpoint: '/receivingTransportShip/create',
				body,
				workers,
				createWorkerApi
			})
		}

		const removeFactory = async () => {
			await removeDetail(`/receivingTransportShip/${idDetail}`)
		}

		const createWorkerApi = async ({ id, workerId, postId }) => {
			const { post, data } = useApi('/receivingTransportShip/createReceivingTransportShip2worker')

			await post({
				receivingTransportShip: { id: id || idDetail },
				worker: { id: workerId },
				workerPost: { id: postId }
			})

			return data.value?.id
		}

		const updateWorkerApi = () => {
			return new Promise((resolve, reject) => {
				(async () => {
					try {
						const { get, data, error } = useApi(`/receivingTransportShip/${idDetail}`)

						await get()

						if (error.value) {
							throw new Error('Не удалось обновить список работников')
						}

						const workerList = data.value.listOfWorkers.reduce((acc, worker) => {
							const { id, workerFIO: title, postName: status } = worker
							acc[id] = { id, title, status }
							return acc
						}, {})
						resolve(workerList)
					} catch (error) {
						reject(error)
					}
				})()
			})
		}

		const { workers, setWorker, createWorker, deleteWorker } = useWorkers({
			updateWorkerApi,
			createWorkerApi,
			deleteEndpoint: '/receivingTransportShip/deleteReceivingTransportShip2worker',
			idDetail,
			isNew
		})

		const { fishes, setFishes } = useFishes()

		const groups = reactive({
			main: {
				title: 'Общие данные',
				inputs: basic
			},
			events: {
				title: 'События',
				hideNew: true,
				count: computed(() => groups.events.collapses.length),
				collapses: fishes
			},
			workers: {
				title: 'Работники',
				count: computed(() => Object.keys(groups.workers.cards).length),
				icon: 'plus',
				onClick: () => {
					modal.value = true
				},
				iconCard: 'user',
				button: 'Удалить',
				buttonClick: deleteWorker,
				cards: workers
			}
		})

		onMounted(async () => {
			if (!isNew) {
				const { get, data, error } = useApi(`/receivingTransportShip/${idDetail}`)

				await get()

				if (error.value) return notFoundDetail()

				const {
					name,
					phone,
					address,
					listOfActions,
					listOfWorkers
				} = data.value

				setBasic({ name, phone, address })

				setFishes(listOfActions.map(fish => ({ ...fish, receivingFio: undefined, workerFIO: fish.receivingFio })))

				listOfWorkers.forEach(work => {
					const { id, workerFIO: title, postName: status } = work
					setWorker({ id, title, status })
				})
			} else {
				setBasic({ name: '', phone: '', address: '' })
			}
		})

		const modal = ref(false)

		return {
			isNew,

			groups,
			modal,
			createWorker,

			removeFactory,
			saveFactory
		}
	}
}
</script>
